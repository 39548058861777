import axios from 'axios';
import { deleteCookie, getCookie, setCookie } from '../utilities/cookies';

const AUTH_TOKEN_COOKIE_NAME = 'authtoken';
const EMAIL_VALUE_COOKIE_NAME = 'emailvlaue';

export function setAuthHeaders() {
  if (getAuthToken().length > 0) {
    axios.defaults.headers.common['Authorization'] = 'Token ' + getAuthToken();
  }
}

export function getAuthToken() {
  return getCookie(AUTH_TOKEN_COOKIE_NAME);
}

export function setAuthToken(token) {
  setCookie(AUTH_TOKEN_COOKIE_NAME, token, 5);
}

export function setEmail(email) {
  setCookie(EMAIL_VALUE_COOKIE_NAME, email, 5);
}

export function getEmail() {
  return getCookie(EMAIL_VALUE_COOKIE_NAME);
}

export function logOut() {
  deleteCookie(AUTH_TOKEN_COOKIE_NAME);
  deleteCookie(EMAIL_VALUE_COOKIE_NAME);
}
