import { deleteCookie, getCookie, setCookie } from '../utilities/cookies';

const DARK_MODE_COOKIE_NAME = 'settingsdarkmode';

export function changeTheme() {
  if (getThemeCookie().length > 0) {
    deleteCookie(DARK_MODE_COOKIE_NAME);
  } else {
    setCookie(DARK_MODE_COOKIE_NAME, true);
  }
}

export function isDarkModeEnabled() {
  return getThemeCookie().length > 0;
}

export function getThemeCookie() {
  return getCookie(DARK_MODE_COOKIE_NAME);
}
