import './App.css';
import React from 'react';

import Application from "./components/Application";

function App() {
  return (
    <Application />
  );
}

export default App;
