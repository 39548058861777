import React, {useState, useCallback} from 'react';
import {
  Toast,
  Page,
  Card,
  Form,
  FormLayout,
  TextField,
  Checkbox,
  Button,
  ChoiceList,
  Loading
} from '@shopify/polaris';
import { API_ARTICLES } from '../../constants/index';
import { sendPost } from '../../utilities/request';

export default function ArticlesNew() {
  const [training, setTraining] = useState(false);
  const [urlError, setUrlError] = useState(false);
  const [articleTextError, setArticleTextError] = useState(false);
  const [articleTitleError, setArticleTitleError] = useState(false);
  const [credible, setCredible] = useState(false);
  const [url, setUrl] = useState('');
  const [done, setDone] = useState(false);
  const [manualInput, setManualInput] = useState(true);
  const [articleText, setArticleText] = useState('');
  const [articleTitle, setArticleTitle] = useState('');
  const [loading, setLoading] = useState(false);

  const handleTextChange = useCallback(
    (newValue) => {
      setArticleText(newValue);
      setArticleTextError(false);
    }, [],
  );

  const handleTitleChange = useCallback(
    (newValue) => {
      setArticleTitle(newValue);
      setArticleTitleError(false);
    }, [],
  );

  const handleChoiceChange = useCallback(
    (selections) => {
      setManualInput(selections[0]);
      setArticleTitleError(false);
      setArticleTextError(false);
    }, [],
  );

  const toggleDone = useCallback(() => setDone(done => !done), []);

  const handleClearButtonClick = useCallback(() => setUrl(''), []);

  const handleSubmit = useCallback((_event) => {
    let valid = true

    if (url.length < 1) {
      setUrlError(true);
      valid = false;
    }

    if (manualInput && articleText.length < 1) {
      setArticleTextError(true);
      valid = false;
    }

    if (manualInput && articleTitle.length < 1) {
      setArticleTitleError(true);
      valid = false;
    }

    if (valid) {
      setLoading(true);
      sendToServer(url, training, credible, articleTitle, articleText, ! manualInput).then((res) => {
        setDone(true);
        setUrlError(false);
        setArticleTitleError(false);
        setArticleTextError(false);
        setTraining(false);
        setCredible(false);
        setManualInput(true);
        setUrl('');
        setArticleTitle('');
        setArticleText('');
      }).finally(() => setLoading(false));
    }
  }, [url, training, credible, articleText, articleTitle, manualInput]);

  const handleTrainingChange = useCallback(
    (value) => setTraining(value),
    [],
  );

  const handleCredibleChange = useCallback(
    (value) => setCredible(value),
    [],
  );

  const handleUrlChange = useCallback(
    (value) => {
      setUrl(value);
      setUrlError(false);
  }, []);

  const toastMarkup = done ? (
    <Toast content="Article Saved" onDismiss={toggleDone} />
  ) : null;

  const breadcrumbs = [{content: 'Articles', url: '/articles'}];
  return (
    <Page
      title="New Article"
      breadcrumbs={breadcrumbs}
    >
      {toastMarkup}
      {loading ? (<Loading />): null}
      <Card title="Add a new article" sectioned>
        <Form onSubmit={handleSubmit}>
          <FormLayout>
            <TextField
              clearButton
              value={url}
              onChange={handleUrlChange}
              label="Full article URL"
              type="url"
              error={urlError ? "Required field" : false}
              onClearButtonClick={handleClearButtonClick}
              helpText={
                <span>
                  This is the full URL pointing to the article.
                </span>
              }
            />
            <ChoiceList
              title="Article information"
              choices={[
                {label: 'Input article information manually', value: true},
                {label: 'Scrape article information automatically', value: false},
              ]}
              selected={[manualInput]}
              onChange={handleChoiceChange}
            />
            <TextField
              title="Article title"
              label="Full article text."
              placeholder={manualInput ? "Article title" : null}
              type="text"
              labelHidden
              value={articleTitle}
              error={articleTitleError ? "Required field" : false}
              disabled={! manualInput}
              onChange={handleTitleChange}
            />
            <TextField
              title="Article text"
              label="Full article text."
              placeholder={manualInput ? "Full article text" : null}
              type="text"
              labelHidden
              multiline={6}
              value={articleText}
              error={articleTextError ? "Required field" : false}
              disabled={! manualInput}
              onChange={handleTextChange}
              helpText={
                <span>
                  Full article text without images.
                </span>
              }
            />
            <Checkbox
              label="Credible article"
              checked={credible}
              onChange={handleCredibleChange}
              helpText={
                <span>
                  This article is credible and does not contain misinformation
                </span>
              }
            />
            <Checkbox
              label="Use in training"
              checked={training}
              onChange={handleTrainingChange}
              helpText={
                <span>
                  Use this article when training the machine learning algorithm
                </span>
              }
            />
            <Button submit>Submit</Button>
          </FormLayout>
       </Form>
      </Card>
    </Page>
  );
}

function sendToServer(url, training, credible, articleTitle, articleText, scrapeArticle) {
  return sendPost(API_ARTICLES, {
    url: url,
    training: training,
    credible: credible,
    articleTitle: articleTitle,
    articleText: articleText,
    scrapeArticle: scrapeArticle,
  });
}
