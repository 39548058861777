import React, {useState, useCallback} from 'react';
import {
  Toast,
  Page,
  Card,
  Form,
  FormLayout,
  TextField,
  Checkbox,
  Button,
  Loading
} from '@shopify/polaris';
import { API_SITES } from '../../constants/index';
import { sendPost } from '../../utilities/request';

export default function SitesNew() {
  const [training, setTraining] = useState(false);
  const [error, setError] = useState(false);
  const [credible, setCredible] = useState(false);
  const [url, setUrl] = useState('');
  const [done, setDone] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggleDone = useCallback(() => setDone(done => !done), []);

  const handleSubmit = useCallback((_event) => {
    if (url.length < 1) {
      setError(true);
    } else {
      setLoading(true);
      sendToServer(url, training, credible).then((res) => {
        setDone(true);
        setError(false);
        setTraining(false);
        setCredible(false);
        setUrl('');
      }).finally(() => setLoading(false));
    }
  }, [url, training, credible]);

  const handleTrainingChange = useCallback(
    (value) => setTraining(value),
    [],
  );

  const handleCredibleChange = useCallback(
    (value) => setCredible(value),
    [],
  );

  const handleUrlChange = useCallback((value) => {
    setUrl(value);
    setError(false);
  }, []);

  const toastMarkup = done ? (
    <Toast content="Site Saved" onDismiss={toggleDone} />
  ) : null;

  const breadcrumbs = [{content: 'Sites', url: '/sites'}];
  return (
    <Page
      title="New Site"
      breadcrumbs={breadcrumbs}
    >
      {toastMarkup}
      {loading ? (<Loading />): null}
      <Card title="Add a new site" sectioned>
        <Form onSubmit={handleSubmit}>
          <FormLayout>
            <TextField
              value={url}
              onChange={handleUrlChange}
              label="Site URL"
              type="url"
              error={error ? "Required field" : false}
              helpText={
                <span>
                  This is the base URL for the site.
                </span>
              }
            />
            <Checkbox
              label="Credible source"
              checked={credible}
              onChange={handleCredibleChange}
              helpText={
                <span>
                  This source is a credible source that does not report misinformation
                </span>
              }
            />
            <Checkbox
              label="Use in training"
              checked={training}
              onChange={handleTrainingChange}
              helpText={
                <span>
                  Use this data source when training the machine learning algorithm
                </span>
              }
            />
            <Button submit>Submit</Button>
          </FormLayout>
        </Form>
      </Card>
    </Page>
  );
}

function sendToServer(url, training, credible) {
  return sendPost(API_SITES, {
    url: url,
    training: training,
    credible: credible,
  });
}
