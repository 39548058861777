import React, {useState, useEffect, useCallback} from 'react';
import {
  Page,
  IndexTable,
  Card,
  TextStyle,
  useIndexResourceState,
  Badge,
  Toast,
  SkeletonBodyText,
  Pagination,
  Loading,
  Link,
  Tooltip,
} from '@shopify/polaris';
import {
  API_SITES,
  API_BULK_EDIT,
  MODEL_SITE,
  FIELD_CREDIBILITY,
  FIELD_TRAINING,
} from '../../constants/index';
import { sendDelete, sendGet, sendPost } from '../../utilities/request';

export default function Component() {
  const [fetching, setFetching] = useState(true);
  const [sites, setSites] = useState([]);
  const [done, setDone] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [nextPage, setNextPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [getPreviousPage, setGetPreviousPage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (fetching) {
      setIsLoading(true);
      const fetchPage = getPreviousPage ? currentPage - 1 : nextPage;
      fetchData(fetchPage)
        .then(res => {
          setGetPreviousPage(false);
          setFetching(false);
          setSites(res.data.data);
          setTotalPages(res.data.total_pages);
          setCurrentPage(res.data.next_page - 1);
        }).finally(() => {
          setIsLoading(false);
          setFetching(false);
        });
    }
  }, [fetching, currentPage, getPreviousPage, nextPage]);

  const toggleDone = useCallback(() => setDone(done => !done), []);

  const toggleFetchingPreviousPage = useCallback(() => {
    setGetPreviousPage(true);
    setFetching(true);
  }, []);

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
  } = useIndexResourceState(sites);

  const handleBulkEdit = useCallback((field, value) => {
    setIsLoading(true);
    sendBulkAction(selectedResources, field, value)
      .then(() => {
        setNextPage(currentPage);
        setFetching(true);
        setDone(true);
      });
  }, [currentPage, selectedResources]);

  const promotedBulkActions = [
    {
      content: 'Delete sites',
      onAction: () => {
        deleteSites(selectedResources).then(() => {
          setNextPage(0);
          setFetching(true);
          setDone(true);
        });
      },
    },
  ];

  const bulkActions = [
    {
      content: 'Mark as credible',
      onAction: () => handleBulkEdit(FIELD_CREDIBILITY, true),
    },
    {
      content: 'Mark as not credible',
      onAction: () => handleBulkEdit(FIELD_CREDIBILITY, false),
    },
    {
      content: 'Add to training',
      onAction: () => handleBulkEdit(FIELD_TRAINING, true),
    },
    {
      content: 'Remove from training',
      onAction: () => handleBulkEdit(FIELD_TRAINING, false),
    },
  ];

  const rowMarkup = sites.map(
    ({id, url, for_training, label, add_date}, index) => (
      <IndexTable.Row
        id={id}
        key={id}
        selected={selectedResources.includes(id)}
        position={index}
      >
        <IndexTable.Cell>
          <Tooltip content={url}>
            <Link monochrome url={"/sites/" + id}>
              <TextStyle variation="strong">{url}</TextStyle>
            </Link>
          </Tooltip>
        </IndexTable.Cell>
        <IndexTable.Cell>{getBadge(! label)}</IndexTable.Cell>
        <IndexTable.Cell>{getBadge(for_training)}</IndexTable.Cell>
        <IndexTable.Cell>{add_date}</IndexTable.Cell>
      </IndexTable.Row>
    ),
  );

  const primaryAction = {content: 'Add Site', url: 'sites/new'};

  const toastMarkup = done ? (
    <Toast content="Action completed" onDismiss={toggleDone} />
  ) : null;

  const resourceName = {
    singular: 'site',
    plural: 'sites',
  };

  const paginationMarkup = totalPages !== 0
    ?(<Pagination
      label={currentPage + 1  + "/" + (totalPages + 1)}
      hasPrevious={currentPage !== 0}
      onPrevious={toggleFetchingPreviousPage}
      hasNext={nextPage <= totalPages}
      onNext={() => setFetching(true)}
    />) : null;

  const loadingMarkup = isLoading ? <Loading /> : null;

  return (
    <Page
      title="Sites"
      primaryAction={primaryAction}
    >
      {toastMarkup}
      {loadingMarkup}
      <Card sectioned>
        {isLoading
          ? (<SkeletonBodyText />)
          : (<IndexTable
            resourceName={resourceName}
            itemCount={sites.length}
            selectedItemsCount={
              allResourcesSelected ? 'All' : selectedResources.length
            }
            onSelectionChange={handleSelectionChange}
            promotedBulkActions={promotedBulkActions}
            bulkActions={bulkActions}
            headings={[
              {title: 'URL'},
              {title: 'Credible source'},
              {title: 'Used in training'},
              {title: 'Data added'},
            ]}
          >
            {rowMarkup}
          </IndexTable>)
        }
        {isLoading ? null : paginationMarkup}
      </Card>
    </Page>
  );
}

function getBadge(value) {
  return value ? <Badge status="success">Yes</Badge> : <Badge status="critical">No</Badge>
}

function fetchData(page) {
  return sendGet(API_SITES, {
    page: page,
  });
}

function deleteSites(idList) {
  return sendDelete(API_SITES, {
    ids: idList,
  });
}

function sendBulkAction(idList, field, value) {
  return sendPost(API_BULK_EDIT, {
    ids: idList,
    model: MODEL_SITE,
    field: field,
    value: value,
  });
}

