import React, { useState, useCallback } from 'react';
import {
  Form,
  FormLayout,
  Layout,
  TextField,
  Card,
  Button,
  SkeletonBodyText,
  TextContainer,
  Heading,
  Link,
  Banner,
  ProgressBar,
} from '@shopify/polaris';
import { API_SCRAPE } from '../../constants';
import { sendPost } from '../../utilities/request';

export default function ArticleScraping() {
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState('');
  const [urlError, setUrlError] = useState(false);
  const [articleData, setArticleData] = useState({});
  const [prediction, setPrediction] = useState(-1);
  const [requestFailed, setRequestFailed] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Something went wrong, try again');

  const handleClearButtonClick = useCallback(() => setUrl(''), []);

  const handleBannerClose = useCallback(() => setRequestFailed(false), []);

  const handleUrlChange = useCallback(
    (value) => {
      setUrl(value);
      setUrlError(false);
  }, []);

  const handleSubmit = useCallback((_event) => {
    setRequestFailed(false);
    if (url.length < 1) {
      setUrlError(true);
    } else {
      setArticleData({});
      setPrediction(-1);
      setLoading(true);
      scrapeUrl(url)
        .then(res => {
          setArticleData(res.data?.article);
          setPrediction(parseFloat(res.data?.prediction).toFixed(4) * 100);
        })
        .catch((error) => {
          setRequestFailed(true);
          setErrorMessage(error.response?.data?.message ?? 'Something went wrong, try again');
        })
        .finally(() => setLoading(false));
    }
  }, [url])

  const errorMarkup = requestFailed
      ? (<Banner title="Scraping Failed" onDismiss={handleBannerClose} status="critical">
          <p>{errorMessage}</p>
        </Banner>)
      : null;

  const resultMarkup = loading
    ? (<Card.Section sectioned><SkeletonBodyText /></Card.Section>)
    : Object.keys(articleData).length === 0
      ? errorMarkup
      : (<Card.Section sectioned>
        <TextContainer>
          <Heading>{articleData?.title}</Heading>
            <p>{articleData?.text}</p>
            <div>
              <Link url={articleData?.url} external>Link to article</Link>
            </div>
        </TextContainer>
      </Card.Section>);

    const predictionMarkup = prediction !== -1
    ? loading
      ? (<Card.Section sectioned><SkeletonBodyText /></Card.Section>)
      : (<Card.Section sectioned>
        <TextContainer>
          <Heading>Probability of this being misinformation or clickbait is {prediction}%</Heading>
          <ProgressBar progress={prediction} />
          <p><b>The predictions are only accurate for Latvian language.</b></p>
          <p><i>The prediction model is in early stages, it is unreliable and should not be used to make judgments.</i></p>
        </TextContainer>
      </Card.Section>)
    : null;

  return (
    <Layout>
      <Layout.AnnotatedSection
        title="Check article validity"
        description="Check if a given text is misinformation. Supports only LV language"
      >
        <Card.Section sectioned>
          <Form onSubmit={handleSubmit}>
            <FormLayout>
              <TextField
                clearButton
                value={url}
                onChange={handleUrlChange}
                label="Full article URL"
                type="url"
                error={urlError ? "Required field" : false}
                onClearButtonClick={handleClearButtonClick}
                helpText={
                  <span>
                    This is the full URL pointing to the article.
                  </span>
                }
              />
              <Button primary={true} submit>Check</Button>
            </FormLayout>
          </Form>
        </Card.Section>
      </Layout.AnnotatedSection>
      <Layout.Section>
        <Card>
          {predictionMarkup}
          {resultMarkup}
        </Card>
      </Layout.Section>
    </Layout>
  );
}

function scrapeUrl(articleUrl) {
  return sendPost(API_SCRAPE, {
    url: articleUrl,
  });
}
