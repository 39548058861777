export const API_URL = process.env.REACT_APP_ENVIRONMENT === 'production'
  ? 'https://flagger.kasparsdev.eu/api/'
  : 'http://localhost:8000/api/';

export const API_SITES = 'flagger/sites';

export const API_ARTICLES = 'flagger/articles';

export const API_BULK_EDIT = 'flagger/edit/bulk';

export const API_PREDICT = 'flagger/ml/predict';
export const API_TRAIN = 'flagger/ml/train';

export const API_SCRAPE = 'flagger/scraper/scrape';
export const API_SCRAPE_SITE = 'flagger/scraper/scrape-site';

export const AUTH_LOGIN_EMAIL = 'auth/email/';
export const AUTH_LOGIN_TOKEN = 'auth/token/';
export const GET_CSRF_TOKEN = 'csrf';

export const MODEL_ARTICLE = 'article';
export const MODEL_SITE = 'site';

export const FIELD_CREDIBILITY = 'credibility';
export const FIELD_TRAINING = 'training';
export const FIELD_SITE_IDE = 'siteId';
