import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AppProvider } from '@shopify/polaris';
import enTranslations from '@shopify/polaris/locales/en.json';
import { isDarkModeEnabled } from './utilities/userSettings';
import { setCsrfHeaders } from './utilities/csrf';

ReactDOM.render(
  <React.StrictMode>
    <AppProvider
      i18n={enTranslations}
      theme={
        {
          "colorScheme": isDarkModeEnabled() ? "dark" : "light",
          "colors": {
            "text": "#D7D7DB",
            "background": "#1E2021",
            "backgroundSubdued": "#121415",
            "icon": "#D7D7DB",
            "interactive": "#5595FA",
            "primary": "#fff",
            "textMuted": "#D7D7DB",
            "textSubtle": "#D7D7DB",
            "subtle": "#1E2021",
            "decorativeOne": "#7A71F9",
            "decorativeTwo": "#C051E9",
            "decorativeThree": "#E43DB0"
          },
          "shadows": {
            "small": "0 0px 0 rgba(149, 157, 165, 0.1)",
            "medium": "rgba(23, 24, 24, 0.05) 0px 0px 0px 0px, rgba(0, 0, 0, 0.15) 0px 0px 0px 0px"
          },
          "input": {
            "backgroundColor": "transparent"
          },
          "topbar": {
            "link": {
              "&.active": {
                "textDecoration": "underline"
              }
            }
          },
          "pageNav": {
            "link": {
              "&.active": {
                "textDecoration": "underline"
              }
            }
          },
          "tag": {
            "backgroundColor": "transparent"
          },
          "fonts": {
            "body": "Roboto Mono, monospace, SFMono-Medium, SF Mono, Segoe UI Mono, Roboto Mono, Ubuntu Mono, Menlo,Consolas, Courier, monospace"
          },
          "space": [
            0,
            4,
            8,
            12,
            16,
            20,
            24,
            28,
            32,
            36,
            40,
            44,
            48
          ],
          "fontSizes": [
            12,
            14,
            16,
            20,
            24,
            32,
            48,
            64,
            96
          ],
          "radii": [
            0,
            4,
            8,
            24,
            "100%"
          ]
        }
      }
    >
      <App />
    </AppProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
setCsrfHeaders();
