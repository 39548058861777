import {
  TextField,
  Page,
  FormLayout,
  Button,
  Form,
  Card,
  Layout,
} from "@shopify/polaris";
import { useCallback, useState } from "react";
import {
  AUTH_LOGIN_EMAIL,
  AUTH_LOGIN_TOKEN,
 } from "../../constants";
import { setAuthToken, setEmail } from "../../utilities/login";
import { sendPost } from "../../utilities/request";

export default function Login() {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Something went wrong, try again');
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(null);
  const [tokenError, setTokenError] = useState(null);

  const handleEmailChange = useCallback((value) => {
    setEmail(value);
    setEmailError(false);
  }, []);

  const handleTokenChange = useCallback((value) => {
    setToken(value);
    setTokenError(false);
  }, []);

  const handleEmailSubmit = useCallback((_event) => {
    if (email.length < 1) {
      setEmailError(true);
      setErrorMessage('Required field');
    } else {
      setLoading(true);
      sendEmail(email)
        .then(res => setEmailSent(true))
        .catch((error) => {
          setErrorMessage(error.response?.data?.detail ?? 'Email not found, please contact the administrator');
          setEmailError(true);
        })
        .finally(() => setLoading(false));
    }
  }, [email]);

  const handleTokenSubmit = useCallback((_event) => {
    if (token.length < 1) {
      setTokenError(true);
      setErrorMessage('Required field');
    } else {
      setLoading(true);
      sendToken(email, token)
        .then(res => setLoginInfo(res.data.token, email))
        .catch((error) => {
          setErrorMessage(error.response?.data?.token.join(' ') ?? 'Something went wrong try again');
          setTokenError(true);
        })
        .finally(() => setLoading(false));
    }
  }, [email, token]);

  const formMarkup = emailSent
    ? (<Form onSubmit={handleTokenSubmit}>
      <FormLayout>
        <TextField
          value={token}
          onChange={handleTokenChange}
          label="Please enter the token that was sent to your email"
          type="number"
          error={tokenError ? errorMessage : false}
          helpText={
            <span>
              Enter the token that was sent to your email
            </span>
          }
        >
        </TextField>
        <Button loading={loading} primary submit>Log in</Button>
      </FormLayout>
    </Form>)
    : (<Form onSubmit={handleEmailSubmit}>
        <FormLayout>
          <TextField
            value={email}
            onChange={handleEmailChange}
            label="Please enter your email"
            type="email"
            error={emailError ? errorMessage : false}
            helpText={
              <span>
                Use the email that has been pre-approved. A login key will be sent to the email.
              </span>
            }
          >
          </TextField>
          <Button loading={loading} submit>Next</Button>
        </FormLayout>
      </Form>
    );

  return (
    <Page title="Login">
      <Layout>
        <Layout.AnnotatedSection
          title="Log in"
          description="Log into the article validity checker. To log in your email needs to be pre-approved by administrators."
        >
          <Card sectioned>
            {formMarkup}
          </Card>
        </Layout.AnnotatedSection>
      </Layout>
    </Page>
  );
}

function sendEmail(email) {
  return sendPost(AUTH_LOGIN_EMAIL, {
    email: email,
  });
}

function sendToken(email, token) {
  return sendPost(AUTH_LOGIN_TOKEN, {
    email: email,
    token: token,
  });
}

function setLoginInfo(token, email) {
  setAuthToken(token);
  setEmail(email);

  window.location.reload();
}
