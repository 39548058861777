import axios from "axios";
import { API_URL } from "../constants";
import { setCsrfHeaders } from "./csrf";
import { setAuthHeaders } from "./login";

export function sendPost(route, payload) {
  setAuthHeaders();
  setCsrfHeaders();

  return axios.post(API_URL + route, payload, {
    timeout: 75 * 1000, // 75s nginx limit
  });
}

export function sendGet(route, payload=null) {
  setAuthHeaders();

  const config = payload
    ? {
      params: payload,
    }
    : null;

  return axios.get(API_URL + route, config);
}

export function sendDelete(route, payload=null) {
  setAuthHeaders();
  setCsrfHeaders();

  const config = payload
    ? {
      data: payload,
    }
    : null;

  return axios.delete(API_URL + route, config);
}
