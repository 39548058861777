import React, {useState, useCallback} from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Articles from './Articles/Articles';
import Landing from './Landing/Landing';
import Sites from './Sites/Sites';
import SiteSingle from './Sites/SiteSingle';
import SitesNew from './Sites/SitesNew';
import Sample from './Sample';
import {
  Frame,
  Navigation,
  TopBar,
  Loading,
  VisuallyHidden,
  Icon,
  Tooltip,
} from '@shopify/polaris';
import {HomeMajor, DomainsMajor, NoteMajor, ColorsMajor} from '@shopify/polaris-icons';
import ArticlesNew from './Articles/ArticlesNew';
import ArticlesSingle from './Articles/ArticlesSingle';
import Login from './Auth/Login';
import { getAuthToken, getEmail, logOut } from '../utilities/login';
import { isDarkModeEnabled, changeTheme } from '../utilities/userSettings';

export default function Application() {
  const [isLoading, setIsLoading] = useState(false);
  const [userMenuActive, setUserMenuActive] = useState(false);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const [email] = useState(getEmail());
  const [darkMode] = useState(isDarkModeEnabled());

  const toggleUserMenuActive = useCallback(
    () => setUserMenuActive((userMenuActive) => !userMenuActive),
    [],
  );
  const toggleIsLoading = useCallback(
    () => setIsLoading((isLoading) => !isLoading),
    [],
  );

  const toggleMobileNavigationActive = useCallback(
    () =>
      setMobileNavigationActive(
        (mobileNavigationActive) => !mobileNavigationActive,
      ),
    [],
  );

  const handleLogOut = useCallback(
    () => {
      logOut();
      window.location.reload();
    },
    [],
  );

  const toggleThemeMode = useCallback(
    () => {
      changeTheme();
      window.location.reload();
    },
    [],
  );

  const loadingMarkup = isLoading ? <Loading /> : null;

  const userMenuActions = [
    {
      items: [
        {content: 'Log Out', onAction: handleLogOut},
      ],
    },
  ];

  const userMenuMarkup = (
    <TopBar.UserMenu
      actions={userMenuActions}
      name={email.split('@')[0]}
      initials={email[0]?.toUpperCase()}
      open={userMenuActive}
      onToggle={toggleUserMenuActive}
    />
  );

  const secondaryMenuMarkup = (
    <TopBar.Menu
      activatorContent={
        <Tooltip content={darkMode ? 'Switch to light mode' : 'Switch to dark mode'}>
          <span onClick={toggleThemeMode}>
            <Icon source={ColorsMajor} />
            <VisuallyHidden>Secondary menu</VisuallyHidden>
          </span>
        </Tooltip>
      }
    />
  );

  const topBarMarkup = (
    <TopBar
      showNavigationToggle={!! email}
      secondaryMenu={secondaryMenuMarkup}
      onNavigationToggle={toggleMobileNavigationActive}
      userMenu={email ? userMenuMarkup : null}
    />
  );

  const pathname = window.location.pathname;
  const navigationMarkup = (
    <Navigation location={pathname === '/' ? '/home' : pathname}>
      <Navigation.Section
        items={[
          {
            url: '/home',
            label: 'Home',
            icon: HomeMajor,
            onClick: toggleIsLoading,
          },
          {
            url: '/sites',
            label: 'Sites',
            icon: DomainsMajor,
            onClick: toggleIsLoading,
          },
          {
            url: '/articles',
            label: 'Articles',
            icon: NoteMajor,
            onClick: toggleIsLoading,
          },
        ]}
      />
  </Navigation>
  );

  return getAuthToken() ? (
    <Frame
      topBar={topBarMarkup}
      navigation={navigationMarkup}
      showMobileNavigation={mobileNavigationActive}
      onNavigationDismiss={toggleMobileNavigationActive}
    >
      {loadingMarkup}
      <Router>
        <Switch>
          <Route path="/sample" component = {Sample}/>
          <Route path="/articles/:id/:siteId" render={(props) => <ArticlesSingle {...props} />}/><Route path="/articles/new" component = {ArticlesNew}/>
          <Route path="/articles/:id" render={(props) => <ArticlesSingle {...props} />}/>
          <Route path="/articles" component = {Articles}/>
          <Route path="/sites/new" component = {SitesNew}/>
          <Route path="/sites/:id" render={(props) => <SiteSingle {...props} />}/>
          <Route path="/sites" component = {Sites}/>
          <Route path="/home" component = {Landing}/>
          <Redirect from="" to="home" />
        </Switch>
      </Router>
    </Frame>
  ) : (
    <Frame
      topBar={topBarMarkup}
    >
      <Router>
        <Route path="/" component = {Login}/>
        <Redirect to="/"/>
      </Router>
    </Frame>
  );
}
