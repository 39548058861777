import axios from 'axios';
import { API_URL, GET_CSRF_TOKEN } from '../constants';
import { getCookie, setCookie } from '../utilities/cookies';

const CSRF_COOKIE_NAME = 'csrftoken';

export function setCsrfHeaders() {
  if (getCsrfToken().length > 0) {
    axios.defaults.xsrfCookieName = CSRF_COOKIE_NAME;
    axios.defaults.xsrfHeaderName = 'X-CSRFToken';
    axios.defaults.withCredentials = true;
    axios.defaults.headers.post['X-CSRFToken'] = getCsrfToken();
  } else {
    axios.get(API_URL + GET_CSRF_TOKEN).then(
      (res) => {
        setCookie(CSRF_COOKIE_NAME, res.data?.csrf, 2);
        axios.defaults.xsrfCookieName = CSRF_COOKIE_NAME;
        axios.defaults.xsrfHeaderName = 'X-CSRFToken';
        axios.defaults.withCredentials = true;
        axios.defaults.headers.post['X-CSRFToken'] = getCsrfToken();
      }
    );
  }
}

export function getCsrfToken() {
  return getCookie(CSRF_COOKIE_NAME)
}
