import React, {useState, useCallback} from 'react';
import {
  Page,
  Tabs,
  Card,
} from '@shopify/polaris';
import ArticleScraping from './ArticleScraping';
import BulkSiteScraping from './BulkSiteScraping';

export default function Landing() {
  const [selected, setSelected] = useState(0);

  const handleTabChange = useCallback(
    (selectedTabIndex) => setSelected(selectedTabIndex),
    [],
  );

  const tabs = [
    {
      id: 'all-customers-1',
      content: 'Scrape Article',
      template: <ArticleScraping />,
      // accessibilityLabel: 'All customers',
      panelID: 'all-customers-content-1',
    },
    {
      id: 'accepts-marketing-1',
      content: 'Bulk Scrape Site',
      template: <BulkSiteScraping />,
      panelID: 'accepts-marketing-content-1',
    },
  ];

  return (
    <Page
      title="Misinformation flagger"
    >
      <Card>
      <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange} fitted>
        <Card.Section title={tabs[selected].content}>
          { tabs[selected].template }
        </Card.Section>
      </Tabs>
    </Card>
    </Page>
  );
}
