import React, {useState, useEffect, useCallback} from 'react';
import {
  Page,
  IndexTable,
  Card,
  TextStyle,
  useIndexResourceState,
  Badge,
  Toast,
  Tooltip,
  Link,
  SkeletonBodyText,
  Pagination,
  Loading,
} from '@shopify/polaris';
import {
  API_ARTICLES,
  API_BULK_EDIT,
  MODEL_ARTICLE,
  FIELD_CREDIBILITY,
  FIELD_TRAINING,
} from '../../constants/index';
import {
  sendGet,
  sendDelete,
  sendPost,
} from '../../utilities/request';

export default function Articles({ siteId }) {
  const [fetching, setFetching] = useState(true);
  // const [loadedFirstPage, setLoadedFirstPage] = useState(true);
  const [articles, setArticles] = useState([]);
  const [done, setDone] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [nextPage, setNextPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [getPreviousPage, setGetPreviousPage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (fetching) {
      setIsLoading(true);
      const fetchPage = getPreviousPage ? currentPage - 1 : nextPage;
      fetchData(fetchPage, siteId)
        .then(res => {
          setGetPreviousPage(false);
          setFetching(false);
          setArticles(res.data.data);
          setNextPage(res.data.next_page);
          setTotalPages(res.data.total_pages);
          setCurrentPage(res.data.next_page - 1);
        }).finally(() => {
          setIsLoading(false);
          setFetching(false);
        });
        setFetching(false);
    }
  }, [fetching, getPreviousPage, currentPage, nextPage, siteId]);

  const toggleDone = useCallback(() => setDone(done => !done), []);

  const toggleFetchingPreviousPage = useCallback(() => {
    setGetPreviousPage(true);
    setFetching(true);
  }, []);

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
  } = useIndexResourceState(articles);

  const handleBulkEdit = useCallback((field, value) => {
    setIsLoading(true);
    sendBulkAction(selectedResources, field, value)
      .then(() => {
        setNextPage(currentPage);
        setFetching(true);
        setDone(true);
      });
  }, [currentPage, selectedResources]);

  const promotedBulkActions = [
    {
      content: 'Delete articles',
      onAction: () => {
        deleteArticles(selectedResources).then(() => {
          setNextPage(0);
          setFetching(true);
          setDone(true);
        });
      },
    },
  ];

  const bulkActions = [
    {
      content: 'Mark as credible',
      onAction: () => handleBulkEdit(FIELD_CREDIBILITY, true),
    },
    {
      content: 'Mark as not credible',
      onAction: () => handleBulkEdit(FIELD_CREDIBILITY, false),
    },
    {
      content: 'Add to training',
      onAction: () => handleBulkEdit(FIELD_TRAINING, true),
    },
    {
      content: 'Remove from training',
      onAction: () => handleBulkEdit(FIELD_TRAINING, false),
    },
  ];

  const rowMarkup = articles.map(
    ({id, title, for_training, label, add_date}, index) => (
      <IndexTable.Row
        id={id}
        key={id}
        selected={selectedResources.includes(id)}
        position={index}
      >
        <IndexTable.Cell>
          <Tooltip content={title}>
            <Link monochrome url={siteId ? '/articles/' + id + '/' + siteId : '/articles/' + id}>
              <TextStyle variation='strong'>{title?.length > 20 ? title.substring(0, 20) + '...' : title}</TextStyle>
            </Link>
          </Tooltip>
        </IndexTable.Cell>
        <IndexTable.Cell>{getBadge(! label)}</IndexTable.Cell>
        <IndexTable.Cell>{getBadge(for_training)}</IndexTable.Cell>
        <IndexTable.Cell>{add_date}</IndexTable.Cell>
      </IndexTable.Row>
    ),
  );

  const primaryAction = {content: 'Add Article', url: '/articles/new'};

  const toastMarkup = done ? (
    <Toast content='Action completed' onDismiss={toggleDone} />
  ) : null;

  const resourceName = {
    singular: 'article',
    plural: 'articles',
  };

  const paginationMarkup = totalPages !== 0
    ?(<Pagination
      label={currentPage + 1  + '/' + (totalPages + 1)}
      hasPrevious={currentPage !== 0}
      onPrevious={toggleFetchingPreviousPage}
      hasNext={nextPage <= totalPages}
      onNext={() => setFetching(true)}
    />) : null;

  const loadingMarkup = isLoading ? <Loading /> : null;

  return (
    <Page
      title='Articles'
      primaryAction={primaryAction}
    >
      {toastMarkup}
      <Card sectioned>
        {loadingMarkup}
        {isLoading
          ? (<SkeletonBodyText />)
          : (<IndexTable
            resourceName={resourceName}
            itemCount={articles.length}
            selectedItemsCount={
              allResourcesSelected ? 'All' : selectedResources.length
            }
            onSelectionChange={handleSelectionChange}
            promotedBulkActions={promotedBulkActions}
            bulkActions={bulkActions}
            headings={[
              {title: 'Title'},
              {title: 'Credible source'},
              {title: 'Used in training'},
              {title: 'Data added'},
            ]}
          >
            {rowMarkup}
          </IndexTable>)
        }
        {isLoading ? null : paginationMarkup}
      </Card>
    </Page>
  );
}
Articles.defaultProps = {
  siteId: null,
}
function getBadge(value) {
  return value ? <Badge status='success'>Yes</Badge> : <Badge status='critical'>No</Badge>
}

function fetchData(page, siteId=null) {
  return sendGet(API_ARTICLES, {
    page: page,
    siteId: siteId,
  });
}

function deleteArticles(idList) {
  return sendDelete(API_ARTICLES, {
    ids: idList,
  });
}

function sendBulkAction(idList, field, value) {
  return sendPost(API_BULK_EDIT, {
    ids: idList,
    model: MODEL_ARTICLE,
    field: field,
    value: value,
  });
}
