import React, {useState, useEffect, useCallback} from 'react';
import {
  Page,
  Card,
  Badge,
  Toast,
  Stack,
  EmptyState,
} from '@shopify/polaris';
import { API_SITES } from '../../constants/index';
import { sendGet } from '../../utilities/request';
import Articles from '../Articles/Articles';

export default function SiteSingle({ match }) {
  const [fetching, setFetching] = useState(true);
  const [site, setSite] = useState({});
  const [done, setDone] = useState(false);
  const [siteId] = useState(match.params.id);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    if (fetching) {
      sendGet(API_SITES + '/' + siteId)
        .then(res => setSite(res.data))
        .catch(() => setNotFound(true))
        .finally(() => setFetching(false));
    }
  }, [fetching, siteId]);

  const toggleDone = useCallback(() => setDone(done => !done), []);

  const breadcrumbs = [{content: 'Sites', url: '/sites'}];

  const toastMarkup = done ? (
    <Toast content="Sites Deleted" onDismiss={toggleDone} />
  ) : null;

  const stackMarkup = notFound ? null : (
    <Stack>
      <Badge status={site?.for_training ? 'success' : 'critical'}>
        {site?.for_training ? 'Used in training' : 'Not used in training'}
      </Badge>
      <Badge status={! site?.label ? 'success' : 'critical'}>
        {! site?.label ? 'Credible' : 'Not credible'}
      </Badge>
    </Stack>
  );

  const contentMarkup = notFound ? (
    <Card sectioned>
      <EmptyState
        heading="Site not found"
        image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
      >
        <p>The site you are trying to look up does not exist.</p>
      </EmptyState>
    </Card>
  ) : (
      <Articles siteId={siteId}></Articles>
    );

  return (
    <Page
      title={"Articles for " + site?.url}
      breadcrumbs={breadcrumbs}
    >
      {toastMarkup}
      {stackMarkup}
      {contentMarkup}
    </Page>
  );
}
