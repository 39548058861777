import React, {useState, useEffect, useCallback} from 'react';
import {
  Page,
  Card,
  Badge,
  Toast,
  Link,
  TextContainer,
  Heading,
  Stack,
  EmptyState,
  SkeletonBodyText,
  ProgressBar,
  Button,
  Form,
  FormLayout
} from '@shopify/polaris';
import { API_ARTICLES } from '../../constants/index';
import { sendGet } from '../../utilities/request';
import { API_SCRAPE } from '../../constants';
import { sendPost } from '../../utilities/request';

export default function ArticlesSingle({ match }) {
  const [fetching, setFetching] = useState(true);
  const [article, setArticle] = useState({});
  const [rating, setRating] = useState({});
  const [done, setDone] = useState(false);
  const [articleId] = useState(match.params.id);
  const [siteId] = useState(match.params.siteId);
  const [notFound, setNotFound] = useState(false);
  const [loading, setLoading] = useState(false);
  const [requestFailed, setRequestFailed] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Something went wrong, try again');
  const [prediction, setPrediction] = useState(-1);

  useEffect(() => {
    if (fetching) {
      sendGet(API_ARTICLES + '/' + articleId)
        .then(res => {
          setArticle(res.data.article);
          setPrediction(res.data?.rating.ai_rating ? parseFloat(res.data?.rating.ai_rating).toFixed(4) * 100 : -1);
        })
        .catch(() => setNotFound(true))
        .finally(() => setFetching(false));
    }
  }, [fetching, articleId]);

  const toggleDone = useCallback(() => setDone(done => !done), []);

  const handlePredictionRequest = useCallback((_event) => {
    setLoading(true);
    scrapeUrl(article)
    .then(res => {
      setFetching(true)
    })
    .catch((error) => {
      setRequestFailed(true);
      setErrorMessage(error.response?.data?.message ?? 'Something went wrong, try again');
    })
    .finally(() => setLoading(false));
  }, [article])

  const breadcrumbs = [
    {
      content: 'Articles',
      url: siteId ? '/sites/' + siteId : '/articles',
    }
  ];

  const toastMarkup = done ? (
    <Toast content="Articles Deleted" onDismiss={toggleDone} />
  ) : null;

  const stackMarkup = (
    <Stack>
      <Badge status={article?.for_training ? 'success' : 'critical'}>
        {article?.for_training ? 'Used in training' : 'Not used in training'}
      </Badge>
      <Badge status={! article?.label ? 'success' : 'critical'}>
        {! article?.label ? 'Credible' : 'Not credible'}
      </Badge>
    </Stack>
  );

  const ratingLevelMarkup = prediction != -1
    ? (
    <Card.Section>
      <TextContainer>
        <Heading>Probability of this being misinformation or clickbait is {prediction}%</Heading>
        <ProgressBar progress={prediction} />
        <p><b>The predictions are only accurate for Latvian language.</b></p>
        <p><i>The prediction model is in early stages, it is unreliable and should not be used to make judgments.</i></p>
      </TextContainer>
    </Card.Section>
    ) : notFound
    ? null
    : (
    <Card.Section>
      <Heading>No AI rating has been found</Heading>
      <Button primary={true} onClick={handlePredictionRequest}>Check</Button>
    </Card.Section>)

  const contentMarkup = notFound ? (
    <Card.Section>
      <EmptyState
        heading="Article not found"
        image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
      >
        <p>The article you are trying to look up does not exist.</p>
      </EmptyState>
    </Card.Section>
  ) : (
    <Card.Section>
      <TextContainer>
        <Heading>{article?.title}</Heading>
        <p>{article?.text}</p>
        <div>{stackMarkup}</div>
        <div>
          <Link url={article?.url} external>Link to article</Link>
        </div>
      </TextContainer>
    </Card.Section>
    );

  return (
    <Page
      title="Single article"
      breadcrumbs={breadcrumbs}
    >
      {toastMarkup}
      <Card sectioned>
        {fetching ? (<SkeletonBodyText />) : contentMarkup}
        {loading ? (<Card.Section sectioned><SkeletonBodyText /></Card.Section>) : ratingLevelMarkup}
      </Card>
    </Page>
  );
}

function scrapeUrl(article) {
  return sendPost(API_SCRAPE, {
    url: article.url,
  });
}
