import React, { useState, useCallback } from 'react';
import {
  Form,
  FormLayout,
  Layout,
  TextField,
  Card,
  Button,
  SkeletonBodyText,
  Banner,
} from '@shopify/polaris';
import { API_SCRAPE_SITE } from '../../constants';
import { sendPost } from '../../utilities/request';

export default function BulkSiteScraping() {
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState('');
  const [urlError, setUrlError] = useState(false);
  const [requestFailed, setRequestFailed] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Something went wrong, try again');
  const [successMessage, setSuccessMessage] = useState('');

  const handleClearButtonClick = useCallback(() => setUrl(''), []);

  const handleBannerClose = useCallback(() => setRequestFailed(false), []);

  const handleSuccessBannerClose = useCallback(() => setSuccessMessage(''), []);

  const handleUrlChange = useCallback(
    (value) => {
      setUrl(value);
      setUrlError(false);
  }, []);

  const handleSubmit = useCallback((_event) => {
    setRequestFailed(false);
    if (url.length < 1) {
      setUrlError(true);
    } else {
      setLoading(true);
      scrapeUrl(url)
        .then(res => {
          setSuccessMessage(res.data.message);
        })
        .catch((error) => {
          setRequestFailed(true);
          setErrorMessage(error.response?.data?.message ?? 'Something went wrong, try again');
        })
        .finally(() => setLoading(false));
    }
  }, [url])

  const errorMarkup = requestFailed
      ? (<Banner title="Scraping Failed" onDismiss={handleBannerClose} status="critical">
          <p>{errorMessage}</p>
        </Banner>)
      : null;

  const resultMarkup = loading
    ? (<Card.Section sectioned><SkeletonBodyText /></Card.Section>)
    : successMessage.length === 0
      ? errorMarkup
      : (<Banner title="Job queued" onDismiss={handleSuccessBannerClose} status="success">
      <p>{successMessage}</p>
    </Banner>);

  return (
    <Layout>
      <Layout.AnnotatedSection
        title="Initiate site scraping job"
        description="Start a site scarping job, you will be notified when the job has been completed"
      >
        <Card.Section sectioned>
          <Form onSubmit={handleSubmit}>
            <FormLayout>
              <TextField
                clearButton
                value={url}
                onChange={handleUrlChange}
                label="Base site URL"
                type="url"
                error={urlError ? "Required field" : false}
                onClearButtonClick={handleClearButtonClick}
                helpText={
                  <span>
                    This is the base URL for the site.
                  </span>
                }
              />
              <Button primary={true} submit>Check</Button>
            </FormLayout>
          </Form>
        </Card.Section>
      </Layout.AnnotatedSection>
      <Layout.Section>
        {resultMarkup}
      </Layout.Section>
    </Layout>
  );
}

function scrapeUrl(siteUrl) {
  return sendPost(API_SCRAPE_SITE, {
    site_url: siteUrl,
  });
}
